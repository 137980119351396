

.std-header {
	color: white;
	background-color: black;

	padding: 20px; 
	.tablet({
		padding: 20px @outside-page-padding 20px @outside-page-padding;

	});
	
	.logo {
		color: white;
		text-decoration: none;
		img {
			max-width: 80%;
		}
	}
	
	.menu {
		list-style: none;
		li {
			margin-left: 10px;
			padding-bottom: 10px;
			a {
				.fs(20);
				color: white;
				text-transform: uppercase;
				text-decoration: none;
				font-weight: 800;
				padding-left: 10px;
				padding-right: 10px;
				padding-bottom: 6px;	
				padding-top: 2px;
			}
		}
	}

	.desktop({
		display: grid;
		grid-template-columns: auto 1fr;
	
		nav {
			justify-self: right;
			align-self: center;
			text-align: right;

		}
		.menu {
			display: inline-block;
			margin: 0;
			padding: 0;
			li {
				display: inline-block;
				a {
				}
				
				&.current {
					border-bottom: solid 2px white;
				}
				
			}
		}
		.tribar {
			display: none;
		}
	});
	.desktop-and-smaller({
		nav {
			position: fixed;
			width: 100%;
			width: 100vw;
			height: 100%;
			height: 100vh;
			z-index: 5;
			top: 0;
			left: -20000px;
			max-width: 350px;
			opacity: 0;
			transition: opacity .3s;
			overflow: auto;
			padding: 50px 15px 0 15px;
			box-sizing: border-box;
			background-color: black; 
			.menu {
				padding: 0;
			}
			
			& > li {
				display: block;
				text-align: center;
				
				& > a {
					text-align: center;
					font-weight: 600;
				}
				
				& > ul {
					position: relative;
					left: auto;
					opacity: 1;
					text-align: center;
					
					a {
						text-align: center;
					}
				}
			}
			
			&.open {
				left: 0;
				opacity: 1;
			}
		}

		.tribar {
			width: 30px;
			margin: 10px 10px 0 0;
			cursor: pointer;
			position: absolute;
			top: 10px;
			right: 10px;
			
			.one, .two, .three {
				border-bottom: solid 3px;
				border-color: white;
			}
			.two {
				margin: 7px 0;
			}
			
		}
	});

}

header {
	.std-header;
}

.page-landing {
	background-color: black;
	text-align: center;
	display: grid; 
	height: 100vh;
	align-items: center;
	justify-items: center;
}